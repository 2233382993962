import * as _ from 'lodash-es';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { RouteLocationNamedRaw, useRoute, useRouter } from 'vue-router';
import { EventData, TabData } from '@repo/core/models';
import { ResourceConfig } from '@repo/core/resources';
import { MessageConstants } from '@repo/core/constants';
import { useClientStore, useEventStore, useFinancialStore, useRootStore, useTabStore } from '@/stores';
import { useDxToast, useDxMessage } from '@repo/vue/utils';
import { useAppStore, useAuthStore } from '@repo/vue/stores';

export const useDxState = () => {
  // computed
  const target = computed(() => window.parent);

  // utils
  const router = useRouter();
  const route = useRoute();
  const dxToast = useDxToast();
  const dxMessage = useDxMessage(target, ResourceConfig.webAngularUrl);

  // stores
  const authStore = useAuthStore();
  const appStore = useAppStore();
  const rootStore = useRootStore();
  const eventStore = useEventStore();
  const clientStore = useClientStore();
  const tabStore = useTabStore();
  const financialStore = useFinancialStore();

  // state
  const { isIFrame } = storeToRefs(appStore);
  const { eventData } = storeToRefs(eventStore);
  const { currentClient } = storeToRefs(clientStore);
  const { tabs, activeTabIndex } = storeToRefs(tabStore);

  // methods
  const go = async (to: RouteLocationNamedRaw) => {
    if (isIFrame.value) {
      const tabId = to.query?.tabId?.toString();
      const message = { ...eventData.value };
      message.event = tabId ? MessageConstants.loadTab : MessageConstants.loadPage;
      message.page = to.name?.toString();
      message.tabId = tabId;
      message.practiceId = rootStore.practiceId;
      message.clientId = rootStore.clientId;
      message.financialYearId = rootStore.financialYearId;
      message.supportAccessRequestId = rootStore.supportAccessRequestId;
      dxMessage.sendMessage(message);
    } else if (to.name && router.hasRoute(to.name)) {
      await router.push(to);
    } else {
      dxToast.error(`Page ${to.name?.toString()} not found`);
    }
  };

  const login = async () => {
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.login;
      dxMessage.sendMessage(message);
    } else {
      await authStore.loginWithRedirect();
    }
  };

  const logout = async () => {
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.logout;
      dxMessage.sendMessage(message);
    } else {
      await authStore.logout();
    }
  };

  const reload = async () => {
    tabStore.resetTabState();
    financialStore.resetFinancialState();
    if (currentClient.value?.id) {
      await openClient(currentClient.value?.id);
    } else {
      await go({ name: 'clients' });
    }
  };

  const openClient = async (clientId: string) => {
    clientStore.openClient(clientId);
    await go({ name: 'process' });
  };

  const loadPage = async (page: string) => {
    await router.push({ name: page });
  };

  const loadTab = async (page: string, tabId: string) => {
    await router.push({ name: page, query: { tabId: tabId } });
  };

  const addTab = async (data: TabData) => {
    if (!data) return;
    tabStore.addTab(data);

    if (data.$name && router.hasRoute(data.$name)) {
      await router.push({
        name: data.$name,
        query: { tabId: data.id }
      });
    } else {
      await router.push({
        name: 'process'
      });
    }
  };

  const removeTab = async (tabId: string) => {
    const tab = _.find(tabs.value, (t) => _.toLower(t.id) === _.toLower(tabId));
    if (!tab) {
      console.error('Tab not found');
      return;
    }

    const closeTabIndex = _.indexOf(tabs.value, tab);
    if (closeTabIndex === -1) {
      console.error('Index of tab not found');
      return;
    }

    const currentTabIndex = activeTabIndex.value;

    // calculate new tab index
    let newTabIndex = currentTabIndex;
    if (closeTabIndex < currentTabIndex || currentTabIndex === tabs.value.length - 1) {
      newTabIndex--;
    }
    newTabIndex = Math.max(newTabIndex, 0);

    // remove the tab
    tabStore.removeTab(tabId);

    if (tabs.value.length > 0) {
      const newTab = tabs.value[newTabIndex];
      await router.push({ name: newTab.$name, query: { tabId: newTab.id } });
    } else {
      await router.push({ name: 'process' });
    }
  };

  const selectedYearChanged = async (financialYearId: string) => {
    await financialStore.loadFinancialOverviewData(financialYearId);
  };

  const sendMessage = (data: EventData) => {
    dxMessage.sendMessage(data);
  };

  return {
    router,
    route,
    isIFrame,
    go,
    login,
    logout,
    reload,
    openClient,
    addTab,
    loadPage,
    loadTab,
    removeTab,
    selectedYearChanged,
    sendMessage
  };
};
